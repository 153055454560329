<template>
  <div> 
    <b-row class="m-0  justify-content-center">
      <b-col
        class="content-header"
        cols="9"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1">
          <h3 class="m-0">{{(userForm.civility==0 ? 'M. ' : userForm.civility==1 ? 'Mme ' : '') + userForm.firstName+ ' ' + userForm.lastName}}</h3>
        </div>
        <div class="content-header-actions d-flex">
            <feather-icon class="cursor-pointer" icon="XIcon" size="24" @click="cancel()" /> 
        </div>
      </b-col>
    </b-row>
    <div v-if="isLoadingUser" class="content-loader-center m-0 h-100">
      <div class="text-center flex-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        Chargement du formulaire...
      </div>
    </div>
    <b-row v-else class="content-scrollable-sticky m-0 h-100 justify-content-center" >
      <!-- <b-col cols="3" class="content-scrollable-sticky-nav">
        <ul>
          <li><a href="#general" class="current">Général</a></li>
          <li><a href="#juridique">Juridique</a></li>
        </ul>
      </b-col> --> 
      <b-col cols="9" class="content-scrollable-sticky-main">

        <validation-observer ref="formCreateUser">
          <b-form autocomplete="off" >
            <b-row id="general" class="my-0">
              <b-col cols="12">
                  <b-row class="justify-content-center">
                      <b-col cols="4">
                          <b-form-group label="Civilité" label-for="civility">
                              <validation-provider #default="{ errors }" name="Civility">
                                  <v-select id="civility" :state="errors.length &gt; 0 ? false : null" :reduce="(type) =&gt; type.value" v-model="userForm.civility" :options="optionCivility"></v-select>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col cols="4">
                      </b-col>
                  </b-row>
              </b-col>  
              <b-col cols="12">
                  <b-row class="justify-content-center">
                      <b-col cols="4">
                          <b-form-group label="Nom *" label-for="lastName">
                              <validation-provider #default="{ errors }" name="lastName" rules="required">
                                  <b-form-input id="lastName" v-model="userForm.lastName" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col cols="4">
                          <b-form-group label="Prénom *" label-for="firstName">
                              <validation-provider #default="{ errors }" name="firstName" rules="required">
                                  <b-form-input id="firstName" v-model="userForm.firstName" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col>
              <b-col cols="12">
                  <b-row class="justify-content-center">
                      <b-col cols="8">
                          <b-form-group label="Email" label-for="email">
                              <validation-provider #default="{ errors }" name="email" rules="email">
                                  <b-form-input id="email" v-model="userForm.email" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col>
              <b-col cols="12" v-if="userForm.id=='' || userForm.id==0">
                  <b-row class="justify-content-center">
                      <b-col cols="4">
                        <b-form-group label="Mot de passe" label-for="password">
                          <validation-provider #default="{ errors }" name="password" :rules="{ required: true, min: 10, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }">
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : null"
                            >
                              <b-form-input
                                id="password" 
                                v-model="userForm.password"
                                :state="errors.length > 0 ? false : null"
                                class="form-control-merge"
                                :type="passwordFieldType"
                                name="password"
                                placeholder="············"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  class="cursor-pointer"
                                  :icon="passwordToggleIcon"
                                  @click="togglePasswordVisibility"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger" v-show="errors[0]">Doit contenir au moins : 1 majuscule, 1 minuscule, 1 chiffre, et 1 caractère spécial</small>
                            <!-- <b-form-input id="password" v-model="userForm.password" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small> -->
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                          <b-form-group label="Confirmer votre mot de passe" label-for="confirmPassword">
                              <validation-provider #default="{ errors }" name="confirmPassword" :rules="'confirmed:password'" data-vv-as="password">
                                  <b-input-group
                                    class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : null"
                                  >
                                    <b-form-input
                                      id="confirmPassword" 
                                      v-model="userForm.confirmPassword"
                                      :state="errors.length > 0 ? false : null"
                                      class="form-control-merge"
                                      :type="passwordFieldType"
                                      name="confirmPassword"
                                      placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                      <feather-icon
                                        class="cursor-pointer"
                                        :icon="passwordToggleIcon"
                                        @click="togglePasswordVisibility"
                                      />
                                    </b-input-group-append>
                                  </b-input-group>
                                  <small class="text-danger" v-show="errors[0]">Confirmation invalide</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col>
              <b-col cols="12">
                  <b-row class="justify-content-center">
                      <b-col cols="8">
                          <b-form-group label="Téléphone" label-for="phoneNumber">
                              <validation-provider #default="{ errors }" name="phoneNumber">
                                  <b-form-input id="phoneNumber" v-model="userForm.phoneNumber" v-mask="'## ## ## ## ##'" :state="errors.length &gt; 0 ? false : null"></b-form-input>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col>
              <b-col cols="12">
                  <b-row class="justify-content-center">
                    <b-col cols="8">
                        <b-form-group label="Environnements affectués" label-for="workspaceIds">
                            <validation-provider #default="{ errors }" name="workspaceIds">
                                <v-select v-if="workspacesList" id="workspaceIds" multiple="multiple" :loading="isLoadingWorkspace" :state="errors.length &gt; 0 ? false : null" v-model="userForm.workspaceIds" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="workspacesList.map(elem => { return { label: elem.socialReason, value: elem.id }  })" :reduce="elem =&gt; elem.value">
                                  <template v-slot:no-option>
                                    <template>Aucun résultat trouvé</template>
                                  </template>
                                </v-select>
                                <v-select v-else id="workspaceIds" multiple="multiple" :state="errors.length &gt; 0 ? false : null" v-model="userForm.workspaceIds" :options="workspacesListTest.map(elem => { return { label: elem.socialReason, value: elem.id }  })" :reduce="elem =&gt; elem.value">
                                  <template v-slot:no-option>
                                    <template>Aucun résultat trouvé</template>
                                  </template>
                                </v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                  </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem;"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="create"
              >
                <feather-icon icon="SaveIcon" v-if="userForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ userForm.id ? 'Modifier' : 'Ajouter' }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>  


    
  </div>
</template>
<script>
import {debounce} from 'lodash';
import Autocomplete from 'vuejs-auto-complete'
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";


configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
      regex: 'Doit contenir au moins : 1 majuscule, 1 minuscule, 1 chiffre, et 1 caractère spécial.'
    }
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      scrolled: false,
      autocompleteUserName:"", 
      autocompleteUserNameData:[],
      autocompleteAddressData:[],
      autocompleteAddressIndex:0,
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      required,
      url,
      email,
      dataOrigine:{},
      userForm:{},
      workspacesListTest:[{
        socialReason:"LSF",
        id:"2f52f7b9-1353-4b64-97b6-6476395c8402"
      }]
    }
  },
  created(){
    this.popupUserEvent(this.id)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    ...mapActions([
      "createUser",
      "fetchUser",
	    "updateUser",
      "archiveUser"
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(".content-scrollable-sticky-nav ul li a");
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach(link => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete(){
      let elements = document.querySelectorAll('.not-autocomplete input');
      if (!elements) {
        return;
      }
      elements.forEach(element => {
        element.setAttribute("autocomplete", "nope");

      });
    },
    popupUserEvent(id) { 
      if (id != '') {
        this.fetchUser(id).then(res => {
          this.userForm = res
          this.dataOrigine=JSON.stringify(this.userForm)
        })
      }else{
        this.initializeForm()
      }
    },
    initializeForm () {
      this.userForm = {
        civility:0,
        id: 0,
        lastName: '',
        firstName: '',
        email: '',
        phoneNumber: '',
        workspaceIds:JSON.parse(localStorage.getItem('login')).login.userInfo.workspaceIds
      }
      this.dataOrigine=JSON.stringify(this.userForm)
    },
    create (e) {
      e.preventDefault()
      this.$refs.formCreateUser
        .validate() 
        .then(success1 => {
          if (success1) {
            if (this.userForm.id) 
              this.updateUser(this.userForm)
            else 
              this.createUser(this.userForm)
            
            this.$nextTick(() => {
              this.$tabs.close({to : "/users"})
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // Controls from
    cancel(){
      console.log("cancel", this.$tabs)
      console.log(this.dataOrigine)
      console.log(JSON.stringify(this.userForm))
      if(this.dataOrigine==JSON.stringify(this.userForm)){
        this.$tabs.close()
      }else{
        this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir quitter l\'édition sans enregistrer ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
          this.$tabs.close()
          }
        })
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingUser",
      "usersList",
      "isLoadingWorkspace",
      "workspacesList"
    ]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    }
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
  },
  directives: {
    Ripple,
    mask
  }
}
</script>
<style lang="scss">
.content-scrollable-sticky{
  // display: grid; 
  // grid-template-columns: min-content 1fr; 
  position:relative;
  .content-scrollable-sticky-nav{
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>